import React from "react"
import Layout from "../../layouts/Layout"
import Helmet from "react-helmet"
import { Sucess } from "../../components/Sucess"

const ContactPageSuccess = () => (
  <Layout>
    <Helmet>
      <title>Message sent successfully</title>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
    </Helmet>
    <Sucess
      to="/"
      title="Your message was sent successfully."
      content="I will contact you as soon as possible."
    />
  </Layout>
)

export default ContactPageSuccess
